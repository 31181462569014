import * as React from 'react'

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'

const IndexPage = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Layout pageTitle="About me">
            <Container maxWidth="lg">
                <Paper elevation={3}>
                    <Grid container spacing={1}>
                        <Grid container item sm={12} md={8} >
                            <Box p={isMobile ? 2 : 10}>
                                <Typography variant="h4" paragraph="true" color="primary">About me</Typography>
                                <Typography align="justify" paragraph="true">I'm a software engineer from Lima, Peru, passionate about new technologies and new software development trends.</Typography>
                                <Typography align="justify" paragraph="true">I have more than 12 years of experience in software development using Java (Cloud, Microservices architecture, JEE, SOA) and open source development frameworks. Expertise applying Agile Methodologies and techniques also, I am Scrum Master Certified.</Typography>
                                <Typography align="justify" paragraph="true">I have created software for Government Entities, Manufacturing Industry, Electric Distribution, and Software Consulting, in USA-based companies and Peruvian industries, as Software Architect, Development Lead, and Independent Consultant.</Typography>
                            </Box>
                        </Grid>
                        <Grid container item sm={12} md={4} justify="center" alignItems="center" justifyContent="center">
                            <Box p={isMobile ? 12 : 4}>
                                <StaticImage
                                    alt="Clifford, a reddish-brown pitbull, posing on a couch and looking stoically at the camera"
                                    src="../images/profileImage.png" 
                                    width="250"/>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Layout>
    )
}

export default IndexPage
